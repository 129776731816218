import React from 'react';
import { Dialog, DialogContent } from '../ui/Dialog';
import { Loader2, XCircle } from 'lucide-react';
import { Button } from '../ui/Button';

interface LoadingModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  status: string;
  onCancel: () => void;
  canCancel?: boolean;
}

export const LoadingModal: React.FC<LoadingModalProps> = ({
  isOpen,
  onOpenChange,
  status,
  onCancel,
  canCancel = true
}) => {
  const handleCancel = () => {
    if (canCancel) {
      onCancel();
      onOpenChange(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => {
      if (!open && canCancel) {
        handleCancel();
      }
    }}>
      <div className="sm:max-w-md mx-auto">
        <DialogContent>
          <div className="flex flex-col items-center justify-center p-6 space-y-6">
            <div className="flex items-center justify-center w-16 h-16 rounded-full bg-purple-100">
              <Loader2 className="w-8 h-8 text-purple-600 animate-spin" />
            </div>
            <div className="text-center space-y-2">
              <h3 className="text-lg font-semibold text-gray-900">
                Processing Your Registration
              </h3>
              <p className="text-sm text-gray-600">
                {status}
              </p>
            </div>
            {canCancel && (
              <div className="pt-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleCancel}
                  className="text-red-600 hover:text-red-700 hover:bg-red-50 border-red-200"
                >
                  <XCircle className="w-4 h-4 mr-2" />
                  Cancel Registration
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};
