import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/Card";
import { Button } from "../components/ui/Button";
import { Badge } from "../components/ui/Badge";
import { auth, db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { UserIcon, CakeIcon, PhoneIcon, MailIcon, CalendarIcon, LayoutDashboardIcon, MessageSquareIcon, LogOutIcon } from 'lucide-react';
import { onAuthStateChanged } from 'firebase/auth';
import { Avatar, AvatarImage, AvatarFallback } from "../components/ui/Avatar";
import { SEO } from '../components/SEO';
import { RegisteredEvents } from '../components/RegisteredEvents';

// Custom Tabs Component
const Tab: React.FC<{ label: string; children: React.ReactNode }> = ({ children }) => <div>{children}</div>;

const Tabs: React.FC<{ children: React.ReactElement[] }> & { Tab: typeof Tab } = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  // Memoize the active content to prevent unnecessary re-renders
  const activeContent = React.useMemo(() => {
    return children[activeTab];
  }, [children, activeTab]);

  return (
    <div>
      <div className="flex border-b">
        {React.Children.map(children, (child, index) => (
          <button
            key={index}
            className={`py-2 px-4 font-medium ${
              index === activeTab
                ? 'border-b-2 border-purple-500 text-purple-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab(index)}
          >
            {child.props.label}
          </button>
        ))}
      </div>
      <div className="py-4">{activeContent}</div>
    </div>
  );
};

Tabs.Tab = Tab;

// UserProfile Interface
interface UserProfile {
  id: string;
  name: string;
  email: string;
  gender: string;
  dateOfBirth: string | Date | { seconds: number; nanoseconds: number };
  eventsAttended: string[];
  eventsCreated: string[];
  subscribers: string[];
  isOrganizer: boolean;
  profilePicture: string;
  phone: string;
  createdAt: string | Date | { seconds: number; nanoseconds: number };
  lastLogin: string | Date | { seconds: number; nanoseconds: number };
}

export const UserProfilePage: React.FC = () => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Memoize the event IDs to prevent unnecessary re-renders
  const attendedEventIds = useMemo(() => {
    return user?.eventsAttended || [];
  }, [user?.id, user?.eventsAttended]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        fetchUserData(currentUser.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchUserData = async (userId: string) => {
    try {
      setLoading(true);
      const userDocRef = doc(db, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);
      
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setUser({
          ...userData,
          id: userId,
          name: userData.name || '',
          email: userData.email || '',
          dateOfBirth: userData.dateOfBirth ? new Date(userData.dateOfBirth.seconds * 1000) : null,
          createdAt: userData.createdAt ? new Date(userData.createdAt.seconds * 1000) : null,
          lastLogin: userData.lastLogin ? new Date(userData.lastLogin.seconds * 1000) : null,
        } as UserProfile);
      } else {
        setError("User profile not found. Please complete your profile setup.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("An error occurred while fetching your profile. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date: string | Date | { seconds: number; nanoseconds: number } | null | undefined) => {
    if (!date) return 'N/A';
    if (date instanceof Date) {
      return date.toLocaleDateString();
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleDateString();
    } else if ('seconds' in date) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    }
    return 'N/A';
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-red-600 mb-4">{error}</p>
        <Button onClick={() => navigate('/login')} className="bg-purple-600 hover:bg-purple-700 text-white">
          Login
        </Button>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <SEO title="User Profile" description={`Profile page for ${user.name}`} />
      <div className="container mx-auto px-4 py-8">
        <Card className="mb-8 shadow-none">
          <CardContent className="flex flex-col md:flex-row items-center p-6">
            <Avatar className="w-24 h-24 md:w-32 md:h-32 mb-4 md:mb-0 md:mr-6 shadow-none overflow-hidden">
              <AvatarImage 
                src={user.profilePicture} 
                alt={user.name}
                className="object-cover"
              />
              <AvatarFallback className="bg-purple-200 text-purple-800 text-3xl font-bold">
                {user.name.charAt(0)}
              </AvatarFallback>
            </Avatar>
            <div className="text-center md:text-left">
              <h1 className="text-2xl font-bold text-purple-800 mb-2">{user.name}</h1>
              <p className="text-gray-600 mb-2">{user.email}</p>
              <div className="flex flex-wrap justify-center md:justify-start gap-2 mb-4">
                <Badge variant="secondary">{user.isOrganizer ? 'Organizer' : 'Attendee'}</Badge>
                <Badge variant="outline">{`${user.subscribers.length} Subscribers`}</Badge>
              </div>
              <div className="flex flex-col sm:flex-row gap-2 mt-4 md:mt-0">
                <Button as={Link} to="/profile/edit" className="bg-purple-600 hover:bg-purple-700 text-white">
                  Edit Profile
                </Button>
                <Button as={Link} to="/feedback" className="bg-orange-500 hover:bg-orange-600 text-white">
                  <MessageSquareIcon className="w-4 h-4 mr-2" />
                  Give Feedback
                </Button>
                {user.isOrganizer && (
                  <>
                    <Button as={Link} to="/organizer/dashboard" variant="outline" className="border-purple-600 text-purple-600 hover:bg-purple-50">
                      <LayoutDashboardIcon className="w-4 h-4 mr-2" />
                      Organizer Dashboard
                    </Button>
                    <Button as={Link} to="/organize" variant="outline" className="border-purple-600 text-purple-600 hover:bg-purple-50">
                      <LayoutDashboardIcon className="w-4 h-4 mr-2" />
                      Organize Events
                    </Button>
                  </>
                )}
                <Button
                  onClick={handleLogout}
                  className="bg-red-500 hover:bg-red-600 text-white"
                >
                  <LogOutIcon className="w-4 h-4 mr-2" />
                  Log Out
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>

        <Tabs>
          <Tabs.Tab label="About">
            <Card>
              <CardHeader>
                <CardTitle>Personal Information</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="flex items-center">
                  <UserIcon className="w-5 h-5 mr-2 text-purple-600" />
                  <span className="font-medium mr-2">Gender:</span> {user.gender}
                </div>
                <div className="flex items-center">
                  <CakeIcon className="w-5 h-5 mr-2 text-purple-600" />
                  <span className="font-medium mr-2">Date of Birth:</span> 
                  {user.dateOfBirth ? formatDate(user.dateOfBirth) : 'Not provided'}
                </div>
                <div className="flex items-center">
                  <PhoneIcon className="w-5 h-5 mr-2 text-purple-600" />
                  <span className="font-medium mr-2">Phone:</span> {user.phone}
                </div>
                <div className="flex items-center">
                  <MailIcon className="w-5 h-5 mr-2 text-purple-600" />
                  <span className="font-medium mr-2">Email:</span> {user.email}
                </div>
                <div className="flex items-center">
                  <CalendarIcon className="w-5 h-5 mr-2 text-purple-600" />
                  <span className="font-medium mr-2">Member Since:</span> {formatDate(user.createdAt)}
                </div>
              </CardContent>
            </Card>
          </Tabs.Tab>
          <Tabs.Tab label="Events">
            <RegisteredEvents
              eventIds={attendedEventIds}
              userId={user.id}
              maxHeight="600px"
            />
          </Tabs.Tab>
        </Tabs>
      </div>
    </>
  );
};
