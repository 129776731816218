import { Event } from '../../../types/Event';
import { RegistrationType } from '../../../types/Event';

// Type guard to check if event has registration types
export const hasRegistrationTypes = (event: Event): event is Event & { registrationTypes: RegistrationType[] } => {
  return !!(event as any).registrationTypes?.length;
};

// Get registration types safely
export const getRegistrationTypes = (event: Event | null): RegistrationType[] => {
  if (!event || !hasRegistrationTypes(event)) return [];
  return event.registrationTypes;
};

// Get registration price safely
export const getRegistrationPrice = (event: Event | null, typeName: string): number | undefined => {
  if (!event || !hasRegistrationTypes(event)) return undefined;
  return event.registrationTypes.find(t => t.name === typeName)?.price;
};

// Helper to check if a value is a Timestamp
export const isTimestamp = (value: any): boolean => {
  return value && typeof value.toDate === 'function';
};

// Helper to format time string
export const formatTime = (timeStr: string): string => {
  const [hours, minutes] = timeStr.split(':');
  const hour = parseInt(hours);
  const period = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 || 12;
  return `${formattedHour}:${minutes} ${period}`;
};

// Helper to check if event has ended
export const hasEventEnded = (event: Event | null): boolean => {
  if (!event) return false;
  
  const now = new Date();
  
  if (event.isSingleDay && event.date && isTimestamp(event.date)) {
    const eventDate = event.date.toDate();
    if (event.endTime) {
      // Parse the end time using the formatTime helper
      const timeStr = formatTime(event.endTime);
      const [time, period] = timeStr.split(' ');
      const [hours, minutes] = time.split(':');
      let hour = parseInt(hours);
      
      // Convert to 24-hour format
      if (period === 'PM' && hour !== 12) hour += 12;
      if (period === 'AM' && hour === 12) hour = 0;
      
      eventDate.setHours(hour, parseInt(minutes));
    } else {
      // If no end time, set to end of day
      eventDate.setHours(23, 59, 59);
    }
    return now > eventDate;
  }
  
  // For multi-day events
  if (!event.isSingleDay && event.toDate && isTimestamp(event.toDate)) {
    const endDate = event.toDate.toDate();
    if (event.endTime) {
      // Parse the end time similar to above
      const timeStr = formatTime(event.endTime);
      const [time, period] = timeStr.split(' ');
      const [hours, minutes] = time.split(':');
      let hour = parseInt(hours);
      
      if (period === 'PM' && hour !== 12) hour += 12;
      if (period === 'AM' && hour === 12) hour = 0;
      
      endDate.setHours(hour, parseInt(minutes));
    } else {
      endDate.setHours(23, 59, 59);
    }
    return now > endDate;
  }
  
  return false;
};
