import React from 'react';
import { Button } from "./ui/Button";
import { Label } from "./ui/Label";
import { MusicIcon, HeartPulseIcon, GraduationCapIcon } from "lucide-react";
import { Event } from '../types/Event';

interface EventCategorySelectionProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventCategorySelection: React.FC<EventCategorySelectionProps> = ({ event, onChange }) => {
  const categories = [
    { id: 'entertainment', label: 'Art & Entertainment', icon: MusicIcon },
    { id: 'fitness', label: 'Fitness', icon: HeartPulseIcon },
    { id: 'learning', label: 'Learning', icon: GraduationCapIcon }
  ];

  const handleCategorySelect = (categoryId: string) => {
    const category = categories.find(c => c.id === categoryId);
    onChange('category', category?.label);
  };

  return (
    <div data-testid="event-category-selection" className="space-y-4">
      <Label>Event Category</Label>
      <div className="grid grid-cols-2 gap-2 sm:flex sm:items-center sm:space-x-2">
        {categories.map(({ id, label, icon: Icon }) => (
          <Button
            key={id}
            type="button"
            variant={event.category === label ? "default" : "outline"}
            onClick={() => handleCategorySelect(id)}
            className={`
              flex items-center justify-center px-4 py-2 w-full sm:w-auto
              ${event.category === label 
                ? "bg-purple-600 text-white" 
                : "border-purple-600 text-purple-600"
              }
            `}
          >
            <Icon className="w-4 h-4 mr-2" />
            {label}
          </Button>
        ))}
      </div>
    </div>
  );
};
