import { db } from '../config/firebase';
import { collection, addDoc, getDocs, query, where, doc, serverTimestamp, updateDoc, Timestamp } from 'firebase/firestore';
import { PromoCode, CreatePromoCodeInput } from '../types/PromoCode';

const PROMO_CODES_COLLECTION = 'promoCodes';

class PromoCodeService {
  async createPromoCode(input: CreatePromoCodeInput): Promise<PromoCode> {
    try {
      // Check if code already exists for this event
      const existingCode = await this.findPromoCodeByEventAndCode(input.eventId, input.code);
      if (existingCode) {
        throw new Error('Promo code already exists for this event');
      }

      const docRef = await addDoc(collection(db, PROMO_CODES_COLLECTION), {
        ...input,
        code: input.code.toUpperCase(),
        usedCount: 0,
        createdAt: serverTimestamp(),
        isActive: true,
      });

      return {
        id: docRef.id,
        ...input,
        code: input.code.toUpperCase(),
        usedCount: 0,
        createdAt: new Date(),
        isActive: true,
      };
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : 'Failed to create promo code');
    }
  }

  async getPromoCodesByEvent(eventId: string): Promise<PromoCode[]> {
    try {
      const q = query(
        collection(db, PROMO_CODES_COLLECTION),
        where('eventId', '==', eventId)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(),
        } as PromoCode;
      });
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : 'Failed to fetch promo codes');
    }
  }

  async togglePromoCodeStatus(promoCodeId: string, isActive: boolean): Promise<void> {
    try {
      const promoCodeRef = doc(db, PROMO_CODES_COLLECTION, promoCodeId);
      await updateDoc(promoCodeRef, {
        isActive: isActive
      });
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : 'Failed to update promo code status');
    }
  }

  async findPromoCodeByEventAndCode(eventId: string, code: string): Promise<PromoCode | null> {
    try {
      const q = query(
        collection(db, PROMO_CODES_COLLECTION),
        where('eventId', '==', eventId),
        where('code', '==', code.toUpperCase())
      );
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        return null;
      }

      const doc = querySnapshot.docs[0];
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(),
      } as PromoCode;
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : 'Failed to find promo code');
    }
  }

  async validateAndUsePromoCode(code: string, eventId: string): Promise<PromoCode> {
    try {
      const q = query(
        collection(db, PROMO_CODES_COLLECTION),
        where('code', '==', code.toUpperCase()),
        where('eventId', '==', eventId),
        where('isActive', '==', true)
      );
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        throw new Error('Invalid or inactive promo code');
      }

      const promoCode = querySnapshot.docs[0];
      const data = promoCode.data() as PromoCode;

      if (data.usedCount >= data.usageLimit) {
        throw new Error('Promo code usage limit reached');
      }

      // Update usage count
      const promoCodeRef = doc(db, PROMO_CODES_COLLECTION, promoCode.id);
      await updateDoc(promoCodeRef, {
        usedCount: data.usedCount + 1
      });

      return {
        id: promoCode.id,
        ...data,
        usedCount: data.usedCount + 1,
        createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(),
      };
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : 'Failed to validate promo code');
    }
  }
}

export const promoCodeService = new PromoCodeService();
