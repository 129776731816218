import React, { useEffect, useState } from 'react';
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { Label } from "./ui/Label";
import { Event, RegistrationType } from '../types/Event';
import { Textarea } from './ui/Textarea';

interface EventPriceSelectionProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventPriceSelection: React.FC<EventPriceSelectionProps> = ({ event, onChange }) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (!event.isFree && event.registrationTypes?.length) {
      const minPrice = Math.min(...event.registrationTypes.map(rt => rt.price));
      onChange('price', minPrice);
    }
  }, [event.registrationTypes, event.isFree]);

  const validatePrice = (price: number | string, index?: number) => {
    if (typeof price === 'string' && price === '') {
      return true;
    }
    const numPrice = typeof price === 'string' ? Number(price) : price;
    if (!numPrice || numPrice <= 0) {
      if (index !== undefined) {
        setErrors(prev => ({
          ...prev,
          [`price_${index}`]: 'Please enter a valid price'
        }));
      } else {
        setErrors(prev => ({
          ...prev,
          price: 'Please enter a valid price'
        }));
      }
      return false;
    }
    if (index !== undefined) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[`price_${index}`];
        return newErrors;
      });
    } else {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors.price;
        return newErrors;
      });
    }
    return true;
  };

  const handleRegistrationTypeChange = (index: number, field: keyof RegistrationType, value: string | number) => {
    const updatedTypes = [...(event.registrationTypes || [])];
    const newValue = field === 'price' ? (value === '' ? '' : Number(value)) : value;
    updatedTypes[index] = { 
      ...updatedTypes[index], 
      [field]: newValue
    };
    onChange('registrationTypes', updatedTypes);
    
    // Validate price if it's being changed
    if (field === 'price') {
      if (value === '') {
        setErrors(prev => {
          const newErrors = { ...prev };
          delete newErrors[`price_${index}`];
          return newErrors;
        });
      } else {
        validatePrice(Number(value), index);
      }
      // Update the main event price to the lowest registration type price
      if (!event.isFree) {
        const validPrices = updatedTypes.map(rt => rt.price).filter(price => typeof price === 'number' && price > 0);
        const minPrice = validPrices.length > 0 ? Math.min(...validPrices) : 0;
        onChange('price', minPrice);
      }
    }
  };

  const addRegistrationType = () => {
    const newType: RegistrationType = { name: '', description: '', price: 0 };
    const updatedTypes = [...(event.registrationTypes || []), newType];
    onChange('registrationTypes', updatedTypes);
  };

  const removeRegistrationType = (index: number) => {
    const updatedTypes = (event.registrationTypes || []).filter((_, i) => i !== index);
    onChange('registrationTypes', updatedTypes);
    
    // Remove any errors associated with this registration type
    setErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[`price_${index}`];
      return newErrors;
    });
  };

  return (
    <div data-testid="event-price-selection" className="space-y-4">
      <div className="space-y-2">
        <Label>Event Price</Label>
        <div className="flex items-center space-x-2">
          <Button
            type="button"
            variant={event.isFree ? "default" : "outline"}
            onClick={() => {
              onChange('isFree', true);
              onChange('registrationTypes', []);
              onChange('price', 0);
              setErrors({});
            }}
            className={event.isFree ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
          >
            Free
          </Button>
          <Button
            type="button"
            variant={!event.isFree ? "default" : "outline"}
            onClick={() => {
              onChange('isFree', false);
              if (!event.registrationTypes?.length) {
                onChange('registrationTypes', [{ name: '', description: '', price: 0 }]);
              }
            }}
            className={!event.isFree ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
          >
            Paid
          </Button>
        </div>
      </div>

      {!event.isFree && (
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <Label>Registration Types</Label>
            {(event.registrationTypes?.length || 0) < 5 && (
              <Button
                type="button"
                variant="outline"
                onClick={addRegistrationType}
                className="text-sm"
              >
                Add Type
              </Button>
            )}
          </div>
          
          {event.registrationTypes?.map((type, index) => (
            <div key={index} className="space-y-3 p-4 border rounded-lg">
              <div className="flex justify-between items-start">
                <Label>Type {index + 1}</Label>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => removeRegistrationType(index)}
                  className="text-red-600 hover:text-red-700"
                >
                  Remove
                </Button>
              </div>
              
              <div className="space-y-2">
                <Input
                  placeholder="Type name"
                  value={type.name}
                  onChange={(e) => handleRegistrationTypeChange(index, 'name', e.target.value)}
                  maxLength={50}
                  className="border-purple-300 focus:border-purple-500 focus:ring-purple-500"
                />
                
                <Textarea
                  placeholder="Description (optional)"
                  value={type.description}
                  onChange={(e) => handleRegistrationTypeChange(index, 'description', e.target.value)}
                  maxLength={100}
                  className="border-purple-300 focus:border-purple-500 focus:ring-purple-500"
                />
                
                <div className="space-y-1">
                  <Input
                    type="number"
                    min="0"
                    value={type.price || ''}
                    onChange={(e) => handleRegistrationTypeChange(index, 'price', e.target.value)}
                    placeholder="Enter price"
                    className={`w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${errors[`price_${index}`] ? 'border-red-500' : ''}`}
                  />
                  {errors[`price_${index}`] && (
                    <p className="text-sm text-red-500">{errors[`price_${index}`]}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};