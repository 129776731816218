import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { db } from '../config/firebase';
import { 
  collection, 
  query, 
  where, 
  onSnapshot, 
  orderBy, 
  limit, 
  updateDoc, 
  doc, 
  serverTimestamp
} from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { Notification } from '../types/Notification';

interface NotificationContextType {
  notifications: Notification[];
  markAsRead: (id: string) => Promise<void>;
  markAsUnread: (id: string) => Promise<void>;
  navigateToNotification: (notification: Notification) => void;
  getUnreadCount: () => number;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    if (!user) return;

    const notificationsRef = collection(db, 'notifications');
    const q = query(
      notificationsRef,
      where('userId', '==', user.uid),
      orderBy('createdAt', 'desc'),
      limit(50)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notifs: Notification[] = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt?.toDate() || new Date(),
          metadata: {
            ...data.metadata,
            lastInteractionAt: data.metadata?.lastInteractionAt?.toDate() || null
          }
        } as Notification;
      });

      const uniqueNotifs = Array.from(new Map(notifs.map(n => [n.id, n])).values())
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

      setNotifications(uniqueNotifs);
    }, (error) => {
      console.error("Error fetching notifications:", error);
    });

    return () => unsubscribe();
  }, [user]);

  const markAsRead = async (id: string) => {
    if (!user || !id) {
      console.error("No user logged in or invalid notification ID");
      return;
    }

    try {
      const notificationRef = doc(db, 'notifications', id);
      const notification = notifications.find(n => n.id === id);
      
      if (!notification) {
        console.error("Notification not found");
        return;
      }

      const now = serverTimestamp();
      const updates: Record<string, any> = {
        'metadata.lastInteractionAt': now
      };

      // If the notification is not yet read or is manually marked as unread, update to read.
      if (!notification.isRead || notification.markedAsUnread) {
        updates.isRead = true;
        updates.markedAsUnread = false;
      }

      await updateDoc(notificationRef, updates);

      // Update local state immediately for better UX.
      setNotifications(prev => prev.map(n => {
        if (n.id === id) {
          return {
            ...n,
            isRead: updates.isRead !== undefined ? updates.isRead : n.isRead,
            markedAsUnread: updates.markedAsUnread !== undefined ? updates.markedAsUnread : n.markedAsUnread,
            metadata: {
              ...n.metadata,
              lastInteractionAt: new Date()
            }
          };
        }
        return n;
      }));
    } catch (error) {
      console.error("Error marking notification as read:", error);
      throw error;
    }
  };

  const markAsUnread = async (id: string) => {
    if (!user || !id) {
      console.error("No user logged in or invalid notification ID");
      return;
    }

    try {
      const notificationRef = doc(db, 'notifications', id);
      const notification = notifications.find(n => n.id === id);
      
      if (!notification) {
        console.error("Notification not found");
        return;
      }

      const now = serverTimestamp();
      const updates: Record<string, any> = {
        'metadata.lastInteractionAt': now,
        isRead: false,
        markedAsUnread: true,
      };

      await updateDoc(notificationRef, updates);

      setNotifications(prev => prev.map(n => {
        if (n.id === id) {
          return {
            ...n,
            isRead: false,
            markedAsUnread: true,
            metadata: {
              ...n.metadata,
              lastInteractionAt: new Date()
            }
          };
        }
        return n;
      }));
    } catch (error) {
      console.error("Error marking notification as unread:", error);
      throw error;
    }
  };

  const navigateToNotification = (notification: Notification) => {
    if (!notification.eventId) return;
    
    if (notification.type === 'event_update' || notification.type === 'organizer_message') {
      window.location.href = `/events/${notification.eventId}/messages${
        notification.metadata?.messageId ? `?highlight=${notification.metadata.messageId}` : ''
      }`;
    } else {
      window.location.href = `/events/${notification.eventId}`;
    }
  };

  const getUnreadCount = () => {
    return notifications.filter(n => !n.isRead || n.markedAsUnread).length;
  };

  return (
    <NotificationContext.Provider value={{ 
      notifications, 
      markAsRead, 
      markAsUnread,
      navigateToNotification,
      getUnreadCount 
    }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};
