import React, { useState } from 'react';
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { Loader2, XCircle } from "lucide-react";
import { Checkbox } from "../ui/Checkbox";
import { useToast } from "../../hooks/useToast";
import { promoCodeService } from '../../services/promoCodeService';

interface PromoCodeSectionProps {
  basePrice: number;
  onPriceChange: (newPrice: number) => void;
  disabled?: boolean;
  eventId: string;
}

export const PromoCodeSection: React.FC<PromoCodeSectionProps> = ({
  basePrice,
  onPriceChange,
  disabled = false,
  eventId
}) => {
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isApplyingPromo, setIsApplyingPromo] = useState(false);
  const [promoError, setPromoError] = useState<string | null>(null);
  const [appliedPromo, setAppliedPromo] = useState<{
    code: string;
    discountPercentage: number;
    discountAmount: number;
  } | null>(null);
  const { showToast } = useToast();

  const handleApplyPromoCode = async () => {
    if (!promoCode.trim()) {
      setPromoError('Please enter a promo code');
      return;
    }

    setIsApplyingPromo(true);
    setPromoError(null);

    try {
      const validatedPromo = await promoCodeService.validateAndUsePromoCode(promoCode, eventId);
      
      const discountAmount = (basePrice * validatedPromo.discountPercentage) / 100;
      const newPrice = Math.max(0, basePrice - discountAmount);
      
      setAppliedPromo({
        code: validatedPromo.code,
        discountPercentage: validatedPromo.discountPercentage,
        discountAmount
      });
      
      onPriceChange(newPrice);
      setPromoCode('');
      setPromoError(null);
      
      showToast({
        title: "Success",
        description: `Promo code applied! ${validatedPromo.discountPercentage}% discount`,
      });
    } catch (error) {
      if (error instanceof Error) {
        setPromoError(error.message);
        showToast({
          title: "Error",
          description: error.message,
          variant: "destructive",
        });
        return;
      }
      throw error;
    } finally {
      setIsApplyingPromo(false);
    }
  };

  const handleRemovePromo = () => {
    setAppliedPromo(null);
    onPriceChange(basePrice);
    setShowPromoInput(false);
    setPromoCode('');
  };

  return (
    <div className="space-y-2">
      {!appliedPromo && (
        <div className="flex items-center space-x-2">
          <Checkbox
            id="promo-toggle"
            checked={showPromoInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowPromoInput(e.target.checked)}
            disabled={disabled}
            aria-label="Toggle promo code input"
          />
          <label
            htmlFor="promo-toggle"
            className="text-sm text-purple-600 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            I have a promo code
          </label>
        </div>
      )}
      
      {(showPromoInput || appliedPromo) && (
        <div className="pt-2 space-y-2">
          {!appliedPromo ? (
            <div className="space-y-2">
              <div className="flex gap-2 h-9">
                <Input
                  type="text"
                  placeholder="Enter promo code"
                  value={promoCode}
                  onChange={(e) => {
                    setPromoCode(e.target.value.toUpperCase());
                    setPromoError(null); // Clear error when user types
                  }}
                  className={`h-9 text-purple-600 uppercase ${promoError ? 'border-red-500 focus:ring-red-500' : ''}`}
                  disabled={isApplyingPromo || disabled}
                />
                <Button
                  onClick={handleApplyPromoCode}
                  disabled={isApplyingPromo || !promoCode.trim() || disabled}
                  className="h-9 px-3 whitespace-nowrap"
                  size="sm"
                >
                  {isApplyingPromo ? (
                    <>
                      <Loader2 className="mr-2 h-3 w-3 animate-spin" />
                      <span className="text-sm">Applying...</span>
                    </>
                  ) : (
                    <span className="text-sm">Apply</span>
                  )}
                </Button>
              </div>
              {promoError && (
                <div className="flex items-center gap-2 text-xs text-red-600">
                  <XCircle className="h-3 w-3 flex-shrink-0" />
                  <span>{promoError}</span>
                </div>
              )}
            </div>
          ) : (
            <div className="space-y-2">
              <div className="flex items-center justify-between bg-purple-50 p-2 rounded-md">
                <div className="flex items-center gap-2">
                  <span className="text-sm font-medium text-purple-700">{appliedPromo.code}</span>
                  <span className="text-xs text-green-600">
                    {appliedPromo.discountPercentage}% off
                    {' • '}
                    Saved ₹{appliedPromo.discountAmount.toFixed(2)}
                  </span>
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleRemovePromo}
                  disabled={disabled}
                  className="h-7 px-2 text-xs flex items-center gap-1 text-red-600 hover:text-red-700 hover:bg-red-50 border-red-200"
                >
                  <XCircle className="h-3 w-3" />
                  Remove
                </Button>
              </div>
              <p className="text-xs text-gray-500 italic">
                Only one promo code can be applied at a time
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
