import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

interface ProtectedRouteProps {
  children: React.ReactElement;
  requiresOrganizer?: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiresOrganizer = false }) => {
  const { user, customUser, loading, isFirstTimeUser } = useAuth();
  const location = useLocation();

  if (loading) {
    return null;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (isFirstTimeUser && location.pathname !== '/first-signin') {
    return <Navigate to="/first-signin" replace />;
  }

  if (requiresOrganizer && (!customUser || !customUser.isOrganizer)) {
    return <Navigate to="/organize" replace />;
  }

  return children;
};
