import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Separator } from "../../components/ui/Separator";
import { CheckCircle, Calendar, MapPin, Clock, Info, User, DollarSign } from "lucide-react";
import { registerUserForEvent } from '../../services/eventService';
import { useAuth } from '../../contexts/AuthContext';
import { getUserName } from '../../services/userService';
import { getEventAttendees } from '../../services/eventService';
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/Alert";
import { doc, getDoc, updateDoc, setDoc, serverTimestamp, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { CalendarService } from '../../services/calendarService';
import { razorpayService } from '../../services/razorpayService';
import { transactionService } from '../../services/transactionService';
import { generateBookingConfirmationPdf, uploadPdfToStorage } from '../../services/pdfService';
import { Dialog, DialogContent } from "../../components/ui/Dialog";
import { Loader2 } from "lucide-react";
import { LoadingModal } from "../../components/modals/LoadingModal";
import { PromoCodeSection } from "../../components/promo/PromoCodeSection";
import { useToast } from "../../hooks/useToast";
import { promoCodeService } from '../../services/promoCodeService';

interface RegistrationOption {
  name: string;
  price: number;
  quantity: number;
}

interface EventState {
  eventTitle: string;
  eventDate: string;
  eventTime: string;
  eventLocation: string;
  eventDescription: string;
  organizer: string;
  isFree: boolean;
  price: number;
  pricePerTicket: number;
  quantity: number;
  eventImage: string; 
  attendeeCount: number;
  isOnline: boolean;
  googleCalendarEventId?: string;
  category?: string;
  registrationTypes?: RegistrationOption[];
}

interface OrderRequestData {
  amount: number;
  eventId: string;
  eventName: string;
}

export const RegistrationScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, customUser } = useAuth(); // You can access both Firebase user and custom user data
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const event = location.state as EventState;
  const [organizerName, setOrganizerName] = useState<string>('');
  const [timer, setTimer] = useState<number | null>(null);
  const [capacityHeld, setCapacityHeld] = useState(false);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('Initializing payment gateway...');
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const { showToast } = useToast();
  const [isCancelled, setIsCancelled] = useState(false);

  useEffect(() => {
    const fetchOrganizerName = async () => {
      if (event && event.organizer) {
        try {
          const name = await getUserName(event.organizer);
          setOrganizerName(name);
        } catch (error) {
          console.error('Error fetching organizer name:', error);
        }
      }
    };

    fetchOrganizerName();
  }, [event]);

  useEffect(() => {
    // Initialize the final price when event data is available
    if (event) {
      const basePrice = event.registrationTypes
        ? event.registrationTypes.reduce((total, type) => total + (type.price * type.quantity), 0)
        : event.price;
      
      setFinalPrice(basePrice);
    }
  }, [event]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (timer !== null) {
        setTimer(null);
        setCapacityHeld(false);
        // TODO: Add API call to release capacity if needed
      }
    };
  }, []);

  const startTimer = useCallback((seconds: number) => {
    setTimer(seconds);
    setCapacityHeld(true);

    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev !== null) {
          if (prev <= 1) {
            clearInterval(interval);
            setTimer(null);
            setCapacityHeld(false);
            setError('Time expired. Please try registering again.');
            // TODO: Add API call to release capacity
            return null;
          }
          return prev - 1;
        }
        return null;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const generateAndUploadPdf = async (ticketId: string, amount: number = 0, options: any = {}) => {
    if (isPdfGenerating) {
      console.log('PDF generation already in progress, skipping...');
      return null;
    }

    try {
      setIsPdfGenerating(true);
      if (!user || !customUser || !id || !event) {
        console.error('Missing required data for PDF generation');
        return null;
      }

      const pdfBuffer = await generateBookingConfirmationPdf(
        customUser,
        event,
        {
          id: ticketId,
          ticketId: ticketId,
          amount: amount,
          currency: 'INR',
          quantity: event.quantity,
          pricePerTicket: event.pricePerTicket,
          registrationTypes: event.registrationTypes
        }
      );
      
      const pdfUrl = await uploadPdfToStorage(
        pdfBuffer,
        user.uid,
        id,
        'booking-confirmation'
      );

      // Store PDF URL in user's registration data
      const attendeeDocRef = doc(db, 'events', id, 'attendees', user.uid);
      
      // Check if the document exists
      const attendeeDoc = await getDoc(attendeeDocRef);
      
      if (attendeeDoc.exists()) {
        await updateDoc(attendeeDocRef, {
          confirmationPdfUrl: pdfUrl,
          ticketId
        });
      } else {
        // If document doesn't exist, create it
        await setDoc(attendeeDocRef, {
          userId: user.uid,
          displayName: customUser.displayName || '',
          email: user.email || '',
          phoneNumber: customUser.phoneNumber || '',
          registrationDate: serverTimestamp(),
          confirmationPdfUrl: pdfUrl,
          ticketId
        });
      }

      return pdfUrl;
    } catch (pdfError) {
      console.error('Error generating/uploading PDF:', pdfError);
      // Don't block the registration process if PDF generation fails
      return null;
    } finally {
      setIsPdfGenerating(false);
    }
  };

  const handleRegister = async () => {
    if (!user || !id || !event || !customUser) {
      setError('Missing required data for registration. Please try again.');
      return;
    }

    setIsCancelled(false); // Reset cancellation flag at the start
    const eventId = id as string; // Type assertion for id

    try {
      setIsLoadingPayment(true);
      setLoadingStatus('Initializing payment gateway...');
      
      setLoadingStatus('Securing your spot...');
      startTimer(300); // 5 minutes

      try {
        if (isCancelled) return; // Check for cancellation before preparing payment

        const totalPrice = event.isFree ? 0 : finalPrice;
        
        if (event.isFree) {
          // For free events, directly complete the registration
          setLoadingStatus('Completing registration...');
          const pdfUrl = await generateAndUploadPdf('free-event');
          
          const registrationData = {
            userId: user.uid,
            eventId: eventId,
            eventTitle: event.eventTitle,
            registrationDate: new Date().toISOString(),
            quantity: event.quantity,
            ticketPrice: 0,
            totalAmount: 0,
            paymentStatus: 'completed',
            ticketPdfUrl: pdfUrl,
            registrationTypes: event.registrationTypes?.map(type => ({
              name: type.name,
              quantity: type.quantity,
              price: 0
            })) || []
          };

          await registerUserForEvent(eventId, user.uid, 'free-event');
          setLoadingStatus('Registration completed!');
          navigate(`/events/${id}/registration-confirmation`, {
            state: {
              eventTitle: event.eventTitle,
              eventDate: event.eventDate,
              eventImage: event.eventImage,
              attendeeCount: 0,
              eventId: id,
              isFree: event.isFree,
              price: totalPrice,
              pricePerTicket: event.pricePerTicket,
              quantity: event.quantity,
              category: event.category,
              registrationTypes: event.registrationTypes?.map(type => ({
                name: type.name,
                price: type.price,
                quantity: type.quantity
              }))
            }
          });
          return;
        }

        const ticketDescription = event.category === 'Entertainment' && !event.isOnline && event.registrationTypes
          ? event.registrationTypes
              .filter(type => type.quantity > 0)
              .map(type => `${type.name} (${type.quantity})`)
              .join(', ')
          : event.quantity > 1 ? `${event.quantity} tickets` : '';

        if (isCancelled) return; // Check for cancellation before loading payment gateway

        setLoadingStatus('Loading payment gateway...');
        await razorpayService.handlePayment({
          amount: totalPrice,
          eventTitle: `${event.eventTitle}${ticketDescription ? ` - ${ticketDescription}` : ''}`,
          eventId: eventId,
          userDetails: {
            name: customUser.displayName ?? '',
            email: user.email ?? '',
            phone: customUser.phoneNumber ?? '',
            userId: user.uid
          },
          onSuccess: async (response) => {
            if (isCancelled) return; // Check for cancellation before processing payment success

            setLoadingStatus('Processing payment...');
            try {
              // Create transaction record
              const transaction = await transactionService.createTransaction({
                type: 'payment',
                userId: user.uid,
                eventId: eventId,
                organizerId: event.organizer,
                amount: totalPrice,
                paymentMethod: 'upi',
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
                notes: `Payment for event: ${event.eventTitle}`,
                ticketQuantity: event.quantity,
                registrationTypes: event.registrationTypes?.filter(type => type.quantity > 0).map(type => ({
                  name: type.name,
                  price: type.price,
                  quantity: type.quantity
                }))
              });

              setLoadingStatus('Completing registration...');
              // Register user for the event
              await registerUserForEvent(eventId, user.uid, transaction.id);

              setLoadingStatus('Finalizing...');
              // Get updated attendee count
              const attendees = await getEventAttendees(id);

              // Add to Google Calendar if it's an online event
              if (event.isOnline && event.googleCalendarEventId && user.email) {
                try {
                  await CalendarService.addAttendees(event.googleCalendarEventId, [user.email]);
                } catch (calendarError) {
                  console.error('Error adding to calendar:', calendarError);
                }
              }

              // Generate and upload PDF
              const pdfUrl = await generateAndUploadPdf(response.razorpay_payment_id, totalPrice, {
                ticketId: response.razorpay_payment_id,
                amount: totalPrice,
                quantity: event.quantity,
                pricePerTicket: event.pricePerTicket,
                isFree: false
              });

              const registrationData = {
                userId: user.uid,
                eventId: eventId,
                eventTitle: event.eventTitle,
                registrationDate: new Date().toISOString(),
                quantity: event.quantity,
                ticketPrice: event.pricePerTicket,
                totalAmount: totalPrice,
                paymentId: response.razorpay_payment_id,
                paymentStatus: 'completed',
                ticketPdfUrl: pdfUrl,
                registrationTypes: event.registrationTypes?.map(type => ({
                  name: type.name,
                  price: type.price,
                  quantity: type.quantity
                })) || []
              };

              setTimer(null);
              setCapacityHeld(false);
              setSuccess('Successfully registered for the event!');
              navigate(`/events/${id}/registration-confirmation`, {
                state: {
                  eventTitle: event.eventTitle,
                  eventDate: event.eventDate,
                  eventImage: event.eventImage,
                  attendeeCount: attendees.length,
                  eventId: id,
                  isFree: event.isFree,
                  price: totalPrice,
                  pricePerTicket: event.pricePerTicket,
                  quantity: event.quantity,
                  category: event.category,
                  registrationTypes: event.registrationTypes?.map(type => ({
                    name: type.name,
                    price: type.price,
                    quantity: type.quantity
                  }))
                }
              });
              setIsLoadingPayment(false);
              
            } catch (error) {
              console.error('Error creating transaction:', error);
              setError('Registration failed. Please contact support.');
              setIsLoadingPayment(false);
            }
          },
          onCancel: () => {
            setIsCancelled(true);
            setIsLoadingPayment(false);
            setTimer(null);
            setCapacityHeld(false);
            setLoadingStatus('');
          },
          onError: (error) => {
            setTimer(null);
            setCapacityHeld(false);
            setError('Payment failed. Please try again.');
            console.error('Payment failed:', error);
            setIsLoadingPayment(false);
          }
        });
      } catch (error) {
        console.error('Error processing payment:', error);
        setError('Payment processing failed. Please try again.');
        setTimer(null);
        setCapacityHeld(false);
        setIsLoadingPayment(false);
      }
    } catch (error) {
      console.error('Error in registration:', error);
      setError('Registration failed. Please try again.');
      setIsLoadingPayment(false);
    }
  };

  const handleCancelRegistration = () => {
    setIsCancelled(true);
    setIsLoadingPayment(false);
    setTimer(null);
    setCapacityHeld(false);
    setLoadingStatus('');
  };

  // Add Razorpay script to the page
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!event) {
    return <div>Error: Event details not found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <Card className="max-w-2xl mx-auto shadow-lg">
        <CardContent className="p-8 space-y-8">
          {error && (
            <Alert variant="destructive" className="mb-6">
              <div className="text-lg font-semibold">
                <AlertTitle>Registration Failed</AlertTitle>
              </div>
              <div className="mt-1">
                <AlertDescription>{error}</AlertDescription>
              </div>
            </Alert>
          )}
          {success && (
            <Alert variant="default" className="mb-6">
              <div className="text-lg font-semibold">
                <AlertTitle>Registration Successful</AlertTitle>
              </div>
              <div className="mt-1">
                <AlertDescription>{success}</AlertDescription>
              </div>
            </Alert>
          )}
          
          {/* Event Header */}
          <div className="space-y-3">
            <h1 className="text-2xl font-bold text-purple-700">{event.eventTitle}</h1>
            <div className="relative">
              <button
                onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
                className="flex items-center gap-2 text-gray-600 hover:text-purple-700 transition-colors"
              >
                <span className="text-sm font-medium">
                  {isDescriptionVisible ? 'Hide Description' : 'Show Description'}
                </span>
                <svg
                  className={`w-4 h-4 transition-transform duration-200 ${
                    isDescriptionVisible ? 'rotate-180' : ''
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {isDescriptionVisible && (
                <div className="mt-2 text-gray-600 bg-gray-50 p-4 rounded-lg">
                  {event.eventDescription}
                </div>
              )}
            </div>
          </div>

          {/* Event Details Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 bg-gray-50 rounded-lg p-6 shadow-sm">
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <Calendar className="w-5 h-5 text-purple-600 flex-shrink-0" />
                <span className="text-gray-700">{event.eventDate}</span>
              </div>
              {event.eventTime && (
                <div className="flex items-center space-x-3">
                  <Clock className="w-5 h-5 text-purple-600 flex-shrink-0" />
                  <span className="text-gray-700">{event.eventTime}</span>
                </div>
              )}
              <div className="flex items-center space-x-3">
                <MapPin className="w-5 h-5 text-purple-600 flex-shrink-0" />
                <span className="text-gray-700">{event.eventLocation}</span>
              </div>
            </div>
            
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <User className="w-5 h-5 text-purple-600 flex-shrink-0" />
                <span className="text-gray-700">Organized by: {organizerName || 'Loading...'}</span>
              </div>
              <div className="flex items-center space-x-3">
                <DollarSign className="w-5 h-5 text-purple-600 flex-shrink-0" />
                {event.isFree ? (
                  <span className="text-green-600 font-medium">Free Event</span>
                ) : (
                  <div className="space-y-1">
                    <div className="font-medium text-gray-900">
                      Total Price: ₹{event.price}
                    </div>
                    {event.quantity > 1 && (
                      <div className="text-sm text-gray-600">
                        ({event.quantity} tickets × ₹{event.pricePerTicket} each)
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Registration Types */}
          {event && !event.isFree && event.registrationTypes && (
            <div className="bg-gray-50 rounded-lg p-6 shadow-sm space-y-4">
              <h2 className="text-lg font-semibold text-purple-700 mb-4">Ticket Details</h2>
              {event.registrationTypes
                .filter(type => type.quantity > 0)
                .map((type, index) => (
                  <div key={index} className="flex justify-between items-center py-3 border-b border-gray-100 last:border-0">
                    <span className="text-gray-600 ">{type.name}</span>
                    <div className="flex items-center space-x-4">
                      <span className="text-gray-600">× {type.quantity}</span>
                      <span className="text-gray-600 min-w-[100px] text-right">₹{type.price * type.quantity}</span>
                    </div>
                  </div>
                ))
              }
              {/* {event.registrationTypes.some(type => type.quantity > 0) && (
                <div className="pt-4 mt-4 border-t border-gray-200">
                  <div className="flex justify-between items-center font-semibold text-gray-600">
                    <span className="text-lg">Total</span>
                    <span className="text-lg">₹{event.registrationTypes.reduce((total, type) => total + (type.price * type.quantity), 0)}</span>
                  </div>
                </div>
              )} */}
            </div>
          )}

          {/* Registration Summary */}
          <div className="bg-purple-100 rounded-lg p-6 shadow-sm">
            <div className="flex items-center gap-2 mb-4">
              <Info className="h-4 w-4 text-purple-600" />
              <h2 className="text-lg font-semibold text-purple-700">Registration Summary</h2>
            </div>
            
            {!event.isFree && (
              <>
                <div className="flex justify-between items-center py-2">
                  <span className="text-gray-700">Subtotal</span>
                  <span className="text-gray-700">₹{Number(event.price).toFixed(2)}</span>
                </div>
                
                {/* Promo Code Section */}
                <div className="py-2 border-t border-purple-200">
                  <PromoCodeSection
                    basePrice={event.registrationTypes
                      ? event.registrationTypes.reduce((total, type) => total + (type.price * type.quantity), 0)
                      : event.price}
                    onPriceChange={(newPrice) => setFinalPrice(newPrice)}
                    disabled={isRegistering || isLoadingPayment}
                    eventId={id || ''}
                  />
                </div>
                
                {finalPrice < event.price && (
                  <div className="flex justify-between items-center py-2 text-green-600">
                    <span>Discount Applied</span>
                    <span>-₹{(event.price - finalPrice).toFixed(2)}</span>
                  </div>
                )}
                
                <Separator className="my-3" />
              </>
            )}
            
            <div className="flex justify-between items-center py-3">
              <span className="text-gray-900 font-medium">Total Amount</span>
              <span className="text-lg font-semibold text-gray-900">
                ₹{event.isFree ? '0.00' : finalPrice.toFixed(2)}
              </span>
            </div>
            
            {event.isFree && (
              <div className="flex items-center text-green-600 mt-2 text-sm">
                <CheckCircle className="w-4 h-4 mr-2" />
                <span>This is a free event</span>
              </div>
            )}
          </div>

          {/* Pay and Register Button */}
          <Button 
            className="w-full bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-lg font-medium"
            onClick={handleRegister}
            disabled={isRegistering || capacityHeld}
          >
            {isRegistering 
              ? 'Processing...' 
              : capacityHeld
                ? 'Complete Payment'
                : event.isFree 
                  ? 'Register Now' 
                  : 'Pay and Register'}
          </Button>

          {timer !== null && (
            <Alert className="bg-yellow-50 border-yellow-200">
              <Info className="h-4 w-4 text-yellow-600" />
              <AlertTitle>Registration in Progress</AlertTitle>
              <AlertDescription>
                Time remaining: {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}
                <br />
                Please complete your payment to secure your spot.
              </AlertDescription>
            </Alert>
          )}
        </CardContent>
      </Card>

      <LoadingModal 
        isOpen={isLoadingPayment}
        onOpenChange={setIsLoadingPayment}
        status={loadingStatus}
        onCancel={handleCancelRegistration}
        canCancel={loadingStatus !== 'Processing payment...' && loadingStatus !== 'Finalizing...'}
      />
    </div>
  );
};
