import React, { useState, useEffect } from 'react';
import { Button } from "../../components/ui/Button";
import { Input } from "../../components/ui/Input";
import { Label } from "../../components/ui/Label";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/Table";
import { PlusIcon, ChevronDownIcon, PowerIcon } from "lucide-react";
import { useToast } from "../../hooks/useToast";
import { promoCodeService } from '../../services/promoCodeService';
import { PromoCode, PromoCodeManagementProps } from '../../types/PromoCode';
import { cn } from "../../lib/utils";
import { Badge } from "../../components/ui/Badge";

export const PromoCodeManagement: React.FC<PromoCodeManagementProps> = ({ 
  eventId,
  isFreeEvent = false 
}) => {
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
  const [newCode, setNewCode] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [usageLimit, setUsageLimit] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (eventId) {
      fetchPromoCodes();
    }
  }, [eventId]);

  const fetchPromoCodes = async () => {
    try {
      const codes = await promoCodeService.getPromoCodesByEvent(eventId);
      setPromoCodes(codes);
    } catch (error) {
      showToast({
        title: "Error",
        description: "Failed to fetch promo codes",
        variant: "destructive",
      });
    }
  };

  const handleCreatePromoCode = async () => {
    if (!newCode || !discountPercentage || !usageLimit) {
      showToast({
        title: "Error",
        description: "Please fill in all fields",
        variant: "destructive",
      });
      return;
    }

    const discount = parseInt(discountPercentage);
    if (discount <= 0 || discount > 100) {
      showToast({
        title: "Error",
        description: "Discount percentage must be between 1 and 100",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    try {
      await promoCodeService.createPromoCode({
        code: newCode,
        discountPercentage: discount,
        eventId,
        usageLimit: parseInt(usageLimit),
      });

      showToast({
        title: "Success",
        description: "Promo code created successfully",
      });

      setNewCode('');
      setDiscountPercentage('');
      setUsageLimit('');
      fetchPromoCodes();
    } catch (error) {
      showToast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to create promo code",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTogglePromoCode = async (promoCodeId: string | undefined, currentStatus: boolean) => {
    if (!promoCodeId) {
      showToast({
        title: "Error",
        description: "Invalid promo code",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsLoading(true);
      await promoCodeService.togglePromoCodeStatus(promoCodeId, !currentStatus);
      await fetchPromoCodes();
      showToast({
        title: "Success",
        description: `Promo code ${currentStatus ? 'deactivated' : 'activated'} successfully`,
      });
    } catch (error) {
      showToast({
        title: "Error",
        description: "Failed to update promo code status",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return isFreeEvent ? null : (
    <Card className="w-full">
      <CardHeader 
        className="cursor-pointer hover:bg-gray-50 transition-colors"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center justify-between">
          <CardTitle className="text-purple-900">Promo Codes</CardTitle>
          <ChevronDownIcon 
            className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${
              isExpanded ? 'transform rotate-180' : ''
            }`}
          />
        </div>
      </CardHeader>
      <div className={`transition-all duration-200 ease-in-out ${
        isExpanded ? 'opacity-100 max-h-[2000px]' : 'opacity-0 max-h-0 overflow-hidden'
      }`}>
        <CardContent>
          <div className="grid gap-4">
            <div className="grid grid-cols-4 gap-4">
              <div>
                <Label htmlFor="promoCode">Promo Code</Label>
                <Input
                  id="promoCode"
                  value={newCode}
                  onChange={(e) => setNewCode(e.target.value)}
                  placeholder="Enter promo code"
                  disabled={isLoading}
                />
              </div>
              <div>
                <Label htmlFor="discount">Discount (%)</Label>
                <Input
                  id="discount"
                  type="number"
                  value={discountPercentage}
                  onChange={(e) => setDiscountPercentage(e.target.value)}
                  placeholder="Enter discount percentage"
                  min="1"
                  max="100"
                  disabled={isLoading}
                />
              </div>
              <div>
                <Label htmlFor="usageLimit">Usage Limit</Label>
                <Input
                  id="usageLimit"
                  type="number"
                  value={usageLimit}
                  onChange={(e) => setUsageLimit(e.target.value)}
                  placeholder="Enter usage limit"
                  min="1"
                  disabled={isLoading}
                />
              </div>
              <div className="flex items-end">
                <Button 
                  onClick={handleCreatePromoCode} 
                  className="w-full"
                  disabled={isLoading}
                >
                  <PlusIcon className="w-4 h-4 mr-2" />
                  {isLoading ? "Creating..." : "Create"}
                </Button>
              </div>
            </div>

            <Table className="border border-gray-200 rounded-lg overflow-hidden">
              <TableHeader>
                <TableRow className="bg-gray-50">
                  <TableHead className="text-center font-semibold text-gray-900 border-b border-r border-gray-200 py-4 px-6 first:rounded-tl-lg">Code</TableHead>
                  <TableHead className="text-center font-semibold text-gray-900 border-b border-r border-gray-200 py-4 px-6">Discount</TableHead>
                  <TableHead className="text-center font-semibold text-gray-900 border-b border-r border-gray-200 py-4 px-6">Usage</TableHead>
                  <TableHead className="text-center font-semibold text-gray-900 border-b border-r border-gray-200 py-4 px-6">Created</TableHead>
                  <TableHead className="text-center font-semibold text-gray-900 border-b border-r border-gray-200 py-4 px-6">Status</TableHead>
                  <TableHead className="text-center font-semibold text-gray-900 border-b border-gray-200 py-4 px-6 last:rounded-tr-lg">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {promoCodes.map((promoCode, index) => (
                  <TableRow 
                    key={promoCode.id} 
                    className={`hover:bg-gray-50 ${index === promoCodes.length - 1 ? 'last:border-b-0' : ''}`}
                  >
                    <TableCell className="text-center font-medium text-gray-900 border-r border-gray-200 py-4 px-6">{promoCode.code}</TableCell>
                    <TableCell className="text-center text-gray-900 border-r border-gray-200 py-4 px-6">{promoCode.discountPercentage}%</TableCell>
                    <TableCell className="text-center text-gray-900 border-r border-gray-200 py-4 px-6">{promoCode.usedCount} / {promoCode.usageLimit}</TableCell>
                    <TableCell className="text-center text-gray-900 border-r border-gray-200 py-4 px-6">{new Date(promoCode.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell className="text-center text-gray-900 border-r border-gray-200 py-4 px-6">
                      <Badge 
                        variant={promoCode.isActive ? "success" : "secondary"}
                        className={cn(
                          "px-2 py-1",
                          promoCode.isActive ? "bg-green-100 text-green-800" : "bg-gray-100 text-gray-800"
                        )}
                      >
                        {promoCode.isActive ? "Active" : "Inactive"}
                      </Badge>
                    </TableCell>
                    <TableCell className="text-center py-4 px-6">
                      <Button
                        variant={promoCode.isActive ? "destructive" : "default"}
                        size="sm"
                        onClick={() => handleTogglePromoCode(promoCode.id, promoCode.isActive)}
                        disabled={isLoading}
                        className={cn(
                          "transition-colors inline-flex",
                          promoCode.isActive 
                            ? "hover:bg-red-600" 
                            : "hover:bg-green-600 hover:text-white"
                        )}
                      >
                        <PowerIcon className="w-4 h-4" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {promoCodes.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} className="text-center py-8 text-gray-500">
                      No promo codes found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </div>
    </Card>
  );
};
