import React, { useState } from 'react';
import { BookmarkIcon, ShareIcon, EyeIcon, HeartIcon } from "../../../utils/icons";
import { ShareEventModal } from '../../../components/ShareEventModal';

interface EventHeaderProps {
  title: string;
  imageUrl: string;
  onImageError: () => void;
  isBookmarked?: boolean;
  onBookmarkToggle?: () => void;
  eventId: string;
  bookmarkAriaLabel?: string;
  interestCount?: number;
  viewCount?: number;
}

export const EventHeader: React.FC<EventHeaderProps> = ({ 
  title, 
  imageUrl, 
  onImageError,
  isBookmarked,
  onBookmarkToggle,
  eventId,
  bookmarkAriaLabel = "Bookmark event",
  interestCount = 0,
  viewCount = 0
}) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const handleShare = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowShareModal(true);
  };

  return (
    <div className="relative">
      <img
        src={imageUrl}
        alt={title}
        className="w-full h-64 object-cover"
        onError={onImageError}
      />
      {/* Only show action buttons if bookmark handler is provided */}
      {onBookmarkToggle && (
        <div className="absolute top-4 right-4 flex space-x-2">
          <button
            onClick={onBookmarkToggle}
            className={`p-2 rounded-full transition-colors duration-300 ${
              isBookmarked 
                ? 'bg-purple-500 text-white' 
                : 'bg-white text-purple-500 hover:bg-purple-100'
            }`}
            aria-label={bookmarkAriaLabel}
            data-testid="bookmark-button"
          >
            <BookmarkIcon className={`w-6 h-6 ${isBookmarked ? 'fill-current' : 'stroke-current'}`} />
            <span className="sr-only">{bookmarkAriaLabel}</span>
          </button>
          <button
            className="p-2 rounded-full bg-white text-purple-500 hover:bg-purple-100 transition-colors duration-300"
            onClick={handleShare}
          >
            <ShareIcon className="w-6 h-6" />
            <span className="sr-only">Share event</span>
          </button>
        </div>
      )}
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-purple-900 to-transparent p-6">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold text-white">{title}</h1>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-1">
              <EyeIcon className="w-5 h-5 text-white" />
              <span className="px-2 py-1 text-sm font-semibold text-white bg-purple-500 rounded-full">{viewCount}</span>
            </div>
            <div className="flex items-center space-x-1">
              <HeartIcon className="w-5 h-5 text-white" />
              <span className="px-2 py-1 text-sm font-semibold text-white bg-purple-500 rounded-full">{interestCount}</span>
            </div>
          </div>
        </div>
      </div>

      <ShareEventModal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        eventId={eventId}
        eventTitle={title}
      />
    </div>
  );
};