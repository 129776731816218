import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '../../components/ui/Card';
import { Button } from '../../components/ui/Button';
import { Input } from '../../components/ui/Input';
import { Label } from '../../components/ui/Label';
import { RadioGroup, RadioGroupItem } from '../../components/ui/RadioGroup';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../components/ui/Accordion';
import { Wallet, Building, Trash2 } from 'lucide-react';
import { razorpayService } from '../../services/razorpayService';
import { User, FundAccount, isBankAccountDetails, isVPADetails, BankAccountDetails, VPADetails } from '../../types';

interface FundAccountsPanelProps {
  fundAccounts: FundAccount[];
  onDeleteClick: (id: string) => void;
  deletingId: string | null;
  spacing: any;
  userData: User | null;
  toast: any;
}

const FundAccountsPanel: React.FC<FundAccountsPanelProps> = ({
  fundAccounts,
  onDeleteClick,
  deletingId,
  spacing,
  userData,
  toast,
}) => {
  const [accountType, setAccountType] = useState<'bank_account' | 'vpa'>('bank_account');
  const [newAccount, setNewAccount] = useState<{
    name?: string;
    ifsc?: string;
    account_number?: string;
    address?: string;
  }>({});

  const handleAddAccount = async () => {
    if (accountType === 'bank_account' && (!newAccount.name || !newAccount.ifsc || !newAccount.account_number)) {
      return;
    }
    if (accountType === 'vpa' && !newAccount.address) {
      return;
    }

    try {
      const details: BankAccountDetails | VPADetails = accountType === 'bank_account'
        ? {
            name: newAccount.name!,
            ifsc: newAccount.ifsc!,
            account_number: newAccount.account_number!,
          }
        : {
            address: newAccount.address!,
          };

      await razorpayService.createFundAccount({
        contactId: userData?.razorpayContactId || '',
        accountType,
        details
      });

      // Reset form
      setNewAccount({});
      setAccountType('bank_account');
      toast({
        title: "Success",
        description: "Fund account added successfully",
      });
    } catch (error: any) {
      console.error('Error adding fund account:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to add fund account",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="w-full max-w-2xl mx-auto rounded-[var(--card-radius)] p-6 shadow-md mb-6">
      <CardHeader>
        <CardTitle className="text-lg sm:text-xl">
          Fund Accounts
        </CardTitle>
        <CardDescription className="text-sm sm:text-base text-gray-600">
          Manage your fund accounts for payouts
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Accordion type="single" collapsible className="w-full">
          {fundAccounts.map((account) => (
            <AccordionItem key={account.id} value={account.id}>
              <AccordionTrigger className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  {account.type === 'bank_account' ? (
                    <Building className="h-5 w-5 text-purple-600" />
                  ) : (
                    <Wallet className="h-5 w-5 text-purple-600" />
                  )}
                  <span className="font-medium">
                    {account.type === 'bank_account' && isBankAccountDetails(account.details)
                      ? `${account.details.name} (Bank)`
                      : isVPADetails(account.details)
                      ? `${account.details.address} (UPI)`
                      : 'Unknown Account Type'}
                  </span>
                </div>
                <Trash2 className="h-4 w-4 text-red-500" />
              </AccordionTrigger>
              <AccordionContent>
                <div className="space-y-2 p-2 border-t border-gray-200">
                  {account.type === 'bank_account' && isBankAccountDetails(account.details) ? (
                    <>
                      <p><strong>Name:</strong> {account.details.name}</p>
                      <p><strong>IFSC:</strong> {account.details.ifsc}</p>
                      <p><strong>Account Number:</strong> {account.details.account_number}</p>
                    </>
                  ) : isVPADetails(account.details) ? (
                    <p><strong>UPI Address:</strong> {account.details.address}</p>
                  ) : null}
                  <Button 
                    variant="destructive" 
                    size="sm"
                    onClick={() => onDeleteClick(account.id)}
                    disabled={deletingId === account.id}
                    className="w-full sm:w-auto"
                  >
                    {deletingId === account.id ? (
                      <div className="flex items-center justify-center">
                        <div className="animate-spin mr-2">•</div>
                        Removing...
                      </div>
                    ) : (
                      <>
                        <Trash2 className="h-4 w-4 mr-2" />
                        Delete Account
                      </>
                    )}
                  </Button>
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </CardContent>
      {(!fundAccounts || fundAccounts.length < 3) && (
        <CardFooter>
          <div className="w-full space-y-4 mt-4">
            <RadioGroup 
              value={accountType} 
              onChange={(value: string) => setAccountType(value as 'bank_account' | 'vpa')}
              className="flex items-center justify-center gap-6"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="bank_account" />
                <Label className="text-sm">Bank Account</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="vpa" />
                <Label className="text-sm">UPI</Label>
              </div>
            </RadioGroup>

            {accountType === 'bank_account' ? (
              <div className="space-y-3">
                <Input
                  placeholder="Account Holder Name"
                  value={newAccount.name || ''}
                  onChange={(e) => setNewAccount({ ...newAccount, name: e.target.value })}
                  className="h-10 w-full"
                />
                <Input
                  placeholder="IFSC Code"
                  value={newAccount.ifsc || ''}
                  onChange={(e) => setNewAccount({ ...newAccount, ifsc: e.target.value })}
                  className="h-10 w-full"
                />
                <Input
                  placeholder="Account Number"
                  value={newAccount.account_number || ''}
                  onChange={(e) => setNewAccount({ ...newAccount, account_number: e.target.value })}
                  className="h-10 w-full"
                />
              </div>
            ) : (
              <Input
                placeholder="UPI Address"
                value={newAccount.address || ''}
                onChange={(e) => setNewAccount({ ...newAccount, address: e.target.value })}
                className="h-10 w-full"
              />
            )}

            <Button 
              onClick={handleAddAccount} 
              className="w-full"
              disabled={
                accountType === 'bank_account' 
                  ? !newAccount.name || !newAccount.ifsc || !newAccount.account_number
                  : !newAccount.address
              }
            >
              Add Fund Account
            </Button>
          </div>
        </CardFooter>
      )}
    </Card>
  );
};

export default FundAccountsPanel;
