import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent, CardFooter } from "../../../components/ui/Card";
import { Badge } from "../../../components/ui/Badge";
import { Button } from "../../../components/ui/Button";
import { AlertTriangleIcon, HeartIcon, ChevronDownIcon, ChevronUpIcon, InfoIcon } from "../../../utils/icons";
import { toggleEventInterest, getEventInterestStatus, getEventInterestCount, incrementEventViewCount, getEventViewCount } from '../../../services/eventService';
import { useEventDetail } from '../../../hooks/useEventDetail';
import { EventHeader } from './EventHeader';
import { EventLocationInfo } from './EventLocationInfo';
import { EventDateInfo } from './EventDateInfo';
// import { EventAttendees } from './EventAttendees';
import { Timestamp } from 'firebase/firestore';
import { getImageUrl } from '../../../utils/firebaseUtils';
import { isEventBookmarked, toggleEventBookmark } from '../../../services/bookmarkService';
import { useAuth } from '../../../contexts/AuthContext';
import { EventRating } from './EventRating';
import { hasRegistrationTypes, getRegistrationTypes, getRegistrationPrice, isTimestamp, hasEventEnded } from './EventHelper';
import { Event } from '../../../types/Event';
import EventMetaTags from '../../../components/common/EventMetaTags';
import { useOrganizerDetails } from '../../../hooks/useOrganizerDetails';
import { SEO } from '../../../components/SEO';
import RegistrationStatus from './RegistrationStatus';
import EventEndedStatus from './EventEndedStatus';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { Feedback, RegistrationOption } from './types';
import {DEFAULT_EVENT_IMAGE, formatTime} from './helpers';
import {SkeletonLoader} from './SkeletonLoader';
import { QuantitySpinner } from '../../../components/common/QuantitySpinner';
import { Event as EventType } from '../../../types/Event';




export const EventDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { event, imageUrl: eventImageUrl, attendees, isLoading } = useEventDetail(id);
  const { organizer, organizerAverageRating, organizerEvents, isLoading: organizerLoading } = useOrganizerDetails(event?.organizerId);
  const [imageUrl, setImageUrl] = useState<string>(DEFAULT_EVENT_IMAGE);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [selectedType, setSelectedType] = useState<string>("");
  const [showTooltip, setShowTooltip] = useState<string | null>(null);
  const [isAttendeeState, setIsAttendeeState] = useState(false);
  const [registrationQuantities, setRegistrationQuantities] = useState<Record<string, number>>({});
  const [quantity, setQuantity] = useState(1);
  const [isInterested, setIsInterested] = useState(false);
  const [interestCount, setInterestCount] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [isAboutExpanded, setIsAboutExpanded] = useState(false);
  const [isInterestLoading, setIsInterestLoading] = useState(false);

  useEffect(() => {
    if (eventImageUrl) {
      getImageUrl(eventImageUrl)
        .then(url => {
          setImageUrl(url);
        })
        .catch((error) => {
          console.error('Error fetching image URL:', error);
          setImageUrl(DEFAULT_EVENT_IMAGE);
        });
    } else {
      setImageUrl(DEFAULT_EVENT_IMAGE);
    }
  }, [eventImageUrl]);

  useEffect(() => {
    const fetchBookmarkState = async () => {
      if (user?.uid && id) {
        const bookmarked = await isEventBookmarked(user.uid, id);
        setIsBookmarked(bookmarked);
      }
    };
    fetchBookmarkState();
  }, [user?.uid, id]);

  // Increment view count when page loads
  useEffect(() => {
    const incrementView = async () => {
      if (id) {
        try {
          await incrementEventViewCount(id);
          const newCount = await getEventViewCount(id);
          setViewCount(newCount);
        } catch (error) {
          console.error('Error incrementing view count:', error);
        }
      }
    };
    incrementView();
  }, [id]);

  // Fetch initial interest status and count
  useEffect(() => {
    const fetchInterestData = async () => {
      if (user?.uid && id) {
        try {
          const [status, count] = await Promise.all([
            getEventInterestStatus(id, user.uid),
            getEventInterestCount(id)
          ]);
          setIsInterested(status);
          setInterestCount(count);
        } catch (error) {
          console.error('Error fetching interest data:', error);
        }
      }
    };
    fetchInterestData();
  }, [user?.uid, id]);

  useEffect(() => {
    const checkAttendeeStatus = async () => {
      if (user?.uid && event?.id) {
        try {
          const attendeeDocRef = doc(db, 'events', event.id, 'attendees', user.uid);
          const attendeeDoc = await getDoc(attendeeDocRef);
          setIsAttendeeState(attendeeDoc.exists());
        } catch (error) {
          console.error('Error checking attendee status:', error);
          setIsAttendeeState(false);
        }
      } else {
        setIsAttendeeState(false);
      }
    };
    checkAttendeeStatus();
  }, [user?.uid, event?.id]);

  useEffect(() => {
    if (event && hasRegistrationTypes(event)) {
      const initialQuantities = event.registrationTypes.reduce((acc, type) => ({
        ...acc,
        [type.name]: 0
      }), {});
      setRegistrationQuantities(initialQuantities);
    }
  }, [event]);

  useEffect(() => {
    // If URL is /event/id pattern, redirect to /id
    if (location.pathname.startsWith('/event/')) {
      const eventId = location.pathname.split('/').pop();
      navigate(`/${eventId}`, { replace: true });
    }
  }, [location, navigate]);

  const handleBookmarkToggle = async () => {
    if (!user?.uid || !id) return;

    try {
      // Optimistically update UI
      setIsBookmarked(prev => !prev);
      
      // Perform actual toggle in background
      await toggleEventBookmark(user.uid, id, !isBookmarked);
    } catch (error) {
      // Revert on error
      setIsBookmarked(prev => !prev);
      console.error('Failed to toggle bookmark:', error);
    }
  };

  const handleRate = async (rating: number) => {
    // This will be implemented later when you create the rating service
    console.log('Rating:', rating);
  };

  // Calculate rating and total ratings from feedback
  const calculateRating = (event: Event | null) => {
    if (!event) return 0;
    const feedbackArray = event.feedback as Feedback[] | undefined;
    if (!feedbackArray || feedbackArray.length === 0) return 0;
    
    const sum = feedbackArray.reduce((acc: number, curr: Feedback) => acc + curr.rating, 0);
    return sum / feedbackArray.length;
  };

  const getTotalRatings = (event: Event | null) => {
    if (!event) return 0;
    const feedbackArray = event.feedback as Feedback[] | undefined;
    return feedbackArray?.length || 0;
  };

  const isUserAttendee = () => {
    return isAttendeeState;
  };

  const handleQuantityChange = (typeName: string, newQuantity: number) => {
    setRegistrationQuantities(prev => ({
      ...prev,
      [typeName]: newQuantity
    }));
  };

  const calculateTotal = () => {
    if (!event || !hasRegistrationTypes(event)) return 0;
    return Object.entries(registrationQuantities).reduce((total, [typeName, qty]) => {
      const price = getRegistrationPrice(event, typeName) || 0;
      return total + (price * qty);
    }, 0);
  };

  const handleInterestToggle = async () => {
    if (!user) {
      navigate('/login', { state: { from: location.pathname } });
      return;
    }
    try {
      setIsInterestLoading(true);
      const newInterestStatus = await toggleEventInterest(id!, user.uid);
      setIsInterested(newInterestStatus);
      // Update interest count
      const newCount = await getEventInterestCount(id!);
      setInterestCount(newCount);
      console.log('Interest toggled:', newInterestStatus);
    } catch (error) {
      console.error('Error toggling interest:', error);
    } finally {
      setIsInterestLoading(false);
    }
  };

  const handleRegister = () => {
    if (!event) return;
    if (!user) {
      navigate('/login', { state: { from: location.pathname } });
      return;
    }
    if (event.isCancelled) return;
    if (event.maxCapacity && event.attendeeCount >= event.maxCapacity) return;

    const formatDate = (date: Timestamp | null | undefined): string => {
      if (!date) return 'Date not available';
      return date.toDate().toLocaleDateString();
    };

    const registrationQuantity = (event as EventType).category === 'Entertainment' && !event.isOnline ? quantity : 1;

    navigate(`/${id}/register`, { 
      state: { 
        eventTitle: event.title,
        eventDate: event.isSingleDay 
          ? formatDate(event.date)
          : `${formatDate(event.fromDate)} - ${formatDate(event.toDate)}`,
        eventTime: event.isSingleDay && event.startTime && event.endTime
          ? `${event.startTime} - ${event.endTime}`
          : '',
        eventLocation: event.isOnline 
          ? 'Online Event' 
          : event.location || 'Location not specified',
        eventDescription: event.description,
        organizer: event.organizerId,
        isFree: event.isFree,
        price: event.isFree ? 0 : (hasRegistrationTypes(event) ? calculateTotal() : (Number(event.price) || 0) * registrationQuantity),
        pricePerTicket: event.isFree ? 0 : (hasRegistrationTypes(event) ? calculateTotal() / Object.values(registrationQuantities).reduce((a, b) => a + b, 0) : Number(event.price) || 0),
        quantity: registrationQuantity,
        maxCapacity: event.maxCapacity,
        currentAttendees: event.attendeeCount,
        meetingLink: event.meetingLink,
        skill: event.skill,
        categories: event.categories,
        tags: event.tags,
        category: (event as EventType).category,
        isOnline: event.isOnline,
        googleCalendarEventId: (event as EventType).googleCalendarEventId,
        registrationTypes: hasRegistrationTypes(event) 
          ? event.registrationTypes.map(type => ({
              name: type.name,
              price: type.price,
              quantity: registrationQuantities[type.name] || 0
            }))
          : undefined
      } 
    });
  };



  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (!event) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Card className="max-w-3xl mx-auto p-6">
          <div className="text-center text-gray-600">Event not found</div>
        </Card>
      </div>
    );
  }

  return (
    <>
      <SEO 
        title={event?.title || 'Event Details'} 
        description={`${event.title} - ${event.description || 'Join this exciting event'} | Organized by ${organizer?.name || 'Event Organizer'}`}
      />
      <EventMetaTags event={event} />
      <Card className="max-w-3xl mx-auto overflow-hidden">
        {event.isCancelled && (
          <div className="bg-red-500 text-white py-2 px-4 flex items-center justify-center">
            <AlertTriangleIcon className="w-5 h-5 mr-2" />
            <span className="text-sm font-semibold">Event Cancelled</span>
          </div>
        )}
        
        <EventHeader 
          title={event.title} 
          imageUrl={imageUrl} 
          onImageError={() => setImageUrl(DEFAULT_EVENT_IMAGE)}
          isBookmarked={isBookmarked}
          onBookmarkToggle={user ? handleBookmarkToggle : undefined}
          eventId={id || ''}
          bookmarkAriaLabel="Toggle bookmark"
          interestCount={interestCount}
          viewCount={viewCount}
        />

        <div className="bg-purple-50 p-4 flex flex-wrap justify-center gap-2">
          <Badge variant={event.isFree ? "secondary" : "default"} className="bg-purple-100 text-purple-800">
            {event.isFree ? 'Free' : `₹${event.price}`}
          </Badge>
          <Badge variant="outline" className="border-purple-200 text-purple-800">
            {event.isOnline ? 'Online' : 'In-person'}
          </Badge>
          {event.skill && (
            <Badge variant="outline" className="border-purple-200 text-purple-800">
              {event.skill}
            </Badge>
          )}
          {event.categories?.map((category, index) => (
            <Badge key={index} variant="outline" className="border-purple-200 text-purple-800">
              {category}
            </Badge>
          ))}
        </div>

        <CardContent className="p-4 md:p-6 space-y-4 md:space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-4 md:mb-6">
            <EventLocationInfo 
              event={event} 
              isAttendee={isUserAttendee()} 
            />
            <EventDateInfo event={event} />
          </div>

          {/* About this Event Section */}
          <Card className="w-full">
            <CardContent className="p-4">
              <button 
                onClick={() => setIsAboutExpanded(!isAboutExpanded)}
                className="w-full flex justify-between items-center text-left"
              >
                <div className="flex items-center gap-2">
                  <InfoIcon className="w-5 h-5 text-purple-600" />
                  <h3 className="text-xl font-semibold text-purple-900">About this event</h3>
                </div>
                {isAboutExpanded ? (
                  <ChevronUpIcon className="w-5 h-5 text-purple-600" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5 text-purple-600" />
                )}
              </button>
              
              {isAboutExpanded && (
                <div className="mt-4 space-y-4 text-gray-700">
                  <div className="prose max-w-none">
                    {event?.description || ''}
                  </div>
                </div>
              )}
            </CardContent>
          </Card>

          {event.feedback && event.feedback.length > 0 && (
            <EventRating 
              rating={calculateRating(event)}
              totalRatings={getTotalRatings(event)}
              hasAttended={false}
              onRate={handleRate}
            />
          )}

          {/* Registration Types Section */}
          {event && !event.isFree && hasRegistrationTypes(event) && !event.isCancelled && (
            <Card className="mt-6">
              <CardContent className="p-6">
                <h3 className="text-xl font-semibold mb-4 text-purple-900">Registration Options</h3>
                <div className="space-y-4">
                  {getRegistrationTypes(event).map((type: RegistrationOption) => (
                    <div 
                      key={type.name}
                      className="p-5 border border-gray-200 rounded-lg transition-all duration-200 hover:border-purple-400 hover:shadow-md bg-white"
                    >
                      <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center gap-3">
                          <span className="font-medium text-purple-900">{type.name}</span>
                          {type.description && (
                            <div className="relative inline-block">
                              <button
                                type="button"
                                onMouseEnter={() => setShowTooltip(type.name)}
                                onMouseLeave={() => setShowTooltip(null)}
                                className="text-gray-400 hover:text-purple-600 transition-colors duration-200"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                </svg>
                              </button>
                              {showTooltip === type.name && (
                                <div className="absolute z-10 w-64 p-3 mt-2 text-sm text-white bg-gray-900 rounded-lg shadow-xl -left-28 top-6">
                                  {type.description}
                                  <div className="absolute w-3 h-3 bg-gray-900 transform rotate-45 -top-1.5 left-[7.25rem]"></div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <span className="text-lg font-semibold text-purple-600">₹{type.price}</span>
                      </div>
                      {(event as EventType).category === 'Entertainment' && !event.isOnline ? (
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-3">
                            <button
                              onClick={() => handleQuantityChange(type.name, Math.max(0, registrationQuantities[type.name] - 1))}
                              className="w-9 h-9 flex items-center justify-center rounded-full bg-purple-50 text-purple-700 hover:bg-purple-100 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                              disabled={registrationQuantities[type.name] === 0}
                            >
                              -
                            </button>
                            <span className="w-8 text-center text-gray-900 font-medium">{registrationQuantities[type.name]}</span>
                            <button
                              onClick={() => handleQuantityChange(type.name, registrationQuantities[type.name] + 1)}
                              className="w-9 h-9 flex items-center justify-center rounded-full bg-purple-50 text-purple-700 hover:bg-purple-100 transition-colors duration-200"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center gap-3">
                          <input
                            type="radio"
                            id={type.name}
                            name="registrationType"
                            value={type.name}
                            checked={selectedType === type.name}
                            onChange={(e) => {
                              setSelectedType(e.target.value);
                              setRegistrationQuantities(prev => ({
                                ...prev,
                                [e.target.value]: 1
                              }));
                            }}
                            className="w-4 h-4 text-purple-600 border-gray-300 focus:ring-purple-500"
                          />
                          <label 
                            htmlFor={type.name} 
                            className="flex-grow text-purple-700 cursor-pointer hover:text-gray-900 transition-colors duration-200"
                          >
                            Select this option
                          </label>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          )}
        </CardContent>

        <CardFooter className="bg-purple-50 p-4 md:p-6 flex flex-col gap-2">
          <div className="flex flex-col items-center sm:items-end gap-2 w-full">
            {isUserAttendee() && (event as EventType)?.bookingConfirmationPdfUrl && (
              <Button
                onClick={() => window.open((event as EventType).bookingConfirmationPdfUrl, '_blank')}
                className="w-full sm:w-auto"
                variant="outline"
              >
                Download Ticket
              </Button>
            )}
            {event && !event.isFree && hasRegistrationTypes(event) && (
              ((event as EventType).category === 'Entertainment' && !event.isOnline 
                ? calculateTotal() > 0
                : selectedType) && (
                <div className="text-lg font-semibold">
                  Total: ₹{(event as EventType).category === 'Entertainment' && !event.isOnline 
                    ? calculateTotal()
                    : getRegistrationPrice(event, selectedType)}
                </div>
              )
            )}
            
            <div className="flex items-center justify-center sm:justify-end gap-2 w-full">
              <Button
                onClick={handleRegister}
                disabled={
                  event.isCancelled ||
                  isUserAttendee() ||
                  hasEventEnded(event) ||
                  (event.maxCapacity && event.attendeeCount >= event.maxCapacity) ||
                  ((event as EventType).category === 'Entertainment' && !event.isOnline 
                    ? Object.values(registrationQuantities).every(qty => qty === 0)
                    : hasRegistrationTypes(event) && !selectedType)
                }
                className="flex-1 sm:flex-none max-w-[200px] sm:max-w-none"
              >
                {event.isCancelled 
                  ? 'Event Cancelled' 
                  : isUserAttendee() 
                    ? 'Already Registered'
                    : hasEventEnded(event)
                      ? 'Event Ended'
                      : 'Register Now'
                }
              </Button>
              {!event.isCancelled && !hasEventEnded(event) && (
                <Button
                  onClick={handleInterestToggle}
                  variant="outline"
                  className={`p-2 h-10 ${isInterested ? 'bg-purple-100' : ''}`}
                  aria-label={isInterested ? 'Remove interest' : 'Show interest'}
                  disabled={isInterestLoading}
                >
                  {isInterestLoading ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-purple-600"></div>
                  ) : (
                    <HeartIcon className={`w-5 h-5 ${isInterested ? 'fill-current' : 'stroke-current'}`} />
                  )}
                </Button>
              )}
            </div>
          </div>

          <div 
            className={`flex items-center justify-center sm:justify-start gap-2 ${!event?.isCancelled ? 'cursor-pointer hover:opacity-80' : 'opacity-50 cursor-not-allowed'} transition-opacity bg-purple-50 rounded-lg py-1 px-3 w-full`}
            onClick={() => !event?.isCancelled && navigate(`/organizer/${event?.organizerId}`)}
          >
            <div className="flex flex-col items-center sm:items-start">
              <span className="text-xs text-gray-600">Organized by</span>
              <span className="text-purple-600 font-medium text-sm md:text-base truncate max-w-[200px]">{organizer?.name || 'Unknown Organizer'}</span>
            </div>
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

export default EventDetailPage;