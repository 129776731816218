import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { AccountSummary } from '../../components/organizer/AccountSummary';
import { RecentTransactions } from '../../components/organizer/RecentTransactions';
import { UpcomingEvents } from '../../components/organizer/UpcomingEvents';
import { AlertDialog } from '../../components/ui/AlertDialog';
import { useToast } from '../../components/ui/use-toast';
import { Button } from '../../components/ui/Button';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../config/firebase';
import { razorpayService } from '../../services/razorpayService';
import { User, Transaction, Event, BankAccountDetails, VPADetails, FundAccount } from '../../types';
import { useOrganizerAccountSpacing } from './hooks/useOrganizerAccountSpacing';
import FundAccountsPanel from './FundAccountsPanel';

export const OrganizerAccountPage: React.FC = () => {
  const spacing = useOrganizerAccountSpacing();
  const { user, updateCustomUser } = useAuth();
  const [userData, setUserData] = useState<User | null>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [accountType, setAccountType] = useState<'bank_account' | 'vpa'>('bank_account');
  const [newAccount, setNewAccount] = useState<{
    name?: string;
    ifsc?: string;
    account_number?: string;
    address?: string;
  }>({});
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState<string | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.uid) return;

      try {
        const [userDoc, txs, events] = await Promise.all([
          getDoc(doc(db, 'users', user.uid)),
          getDocs(query(
            collection(db, 'transactions'),
            where('organizerId', '==', user.uid),
            orderBy('timestamp', 'desc'),
            limit(5)
          )),
          getDocs(query(
            collection(db, 'events'),
            where('organizerId', '==', user.uid),
            where('date', '>=', new Date()),
            orderBy('date', 'asc'),
            limit(5)
          ))
        ]);

        if (userDoc.exists()) {
          const fetchedData = userDoc.data();
          setUserData({
            ...user,
            razorpayContactCreated: fetchedData.razorpayContactCreated || false,
            razorpayContactId: fetchedData.razorpayContactId || '',
            fundAccounts: fetchedData.fundAccounts || [],
            maxFundAccounts: fetchedData.maxFundAccounts || 3,
            accountBalance: fetchedData.accountBalance || 0,
            totalEarnings: fetchedData.totalEarnings || 0,
            totalPayouts: fetchedData.totalPayouts || 0,
          } as User);
        }

        setTransactions(txs.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Transaction[]);
        setUpcomingEvents(events.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Event[]);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast({
          title: 'Error',
          description: 'Failed to load data',
          variant: 'destructive',
        });
      }
    };

    fetchData();
  }, [user, toast]);

  const handleAddAccount = async () => {
    if (!userData) {
      toast({
        title: "Error",
        description: "User data not loaded",
        variant: "destructive",
      });
      return;
    }

    try {
      if ((userData.fundAccounts || []).length >= 3) {
        toast({
          title: "Maximum limit reached",
          description: "You have reached the maximum number of fund accounts allowed.",
          variant: "destructive",
        });
        return;
      }

      let contactId = userData.razorpayContactId;
      if (!contactId) {
        const createContact = httpsCallable(getFunctions(), 'createRazorpayContact');
        const result = await createContact();
        contactId = (result.data as { contactId: string }).contactId;

        if (!contactId) throw new Error('Failed to create Razorpay contact');

        await updateCustomUser({
          razorpayContactCreated: true,
          razorpayContactId: contactId,
        });

        setUserData(prev => prev ? {
          ...prev,
          razorpayContactCreated: true,
          razorpayContactId: contactId
        } : null);
      }

      const details: BankAccountDetails | VPADetails = accountType === 'bank_account'
        ? {
            name: newAccount.name!,
            ifsc: newAccount.ifsc!,
            account_number: newAccount.account_number!,
          }
        : {
            address: newAccount.address!,
          };

      const result = await razorpayService.createFundAccount({ contactId, accountType, details });
      const newFundAccount: FundAccount = {
        id: result.fundAccountId,
        type: accountType,
        active: true,
        details
      };

      const updatedFundAccounts = [...(userData.fundAccounts || []), newFundAccount];
      await updateCustomUser({ fundAccounts: updatedFundAccounts });

      setUserData(prev => prev ? { ...prev, fundAccounts: updatedFundAccounts } : null);
      setNewAccount({});
      setAccountType('bank_account');

      toast({
        title: "Success",
        description: "Fund account added successfully",
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to add fund account",
        variant: "destructive",
      });
    }
  };

  const handleDeleteAccount = async (id: string) => {
    try {
      setDeletingId(id);

      if (!userData) throw new Error('User data not available');

      const accountToDeactivate = userData.fundAccounts?.find(acc => acc.id === id);
      if (!accountToDeactivate) throw new Error('Fund account not found');

      await razorpayService.deactivateFundAccount(id);

      const updatedFundAccounts = (userData.fundAccounts || []).filter(account => account.id !== id);
      await updateCustomUser({ fundAccounts: updatedFundAccounts });

      setUserData(prev => prev ? { ...prev, fundAccounts: updatedFundAccounts } : null);

      toast({
        title: "Account Removed",
        description: "Fund account has been successfully removed.",
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to remove fund account",
        variant: "destructive",
      });
    } finally {
      setDeletingId(null);
      setAccountToDelete(null);
      setIsDeleteDialogOpen(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div 
        className="container mx-auto px-4 py-8 lg:px-8 lg:py-12"
        style={{ '--content-max-width': `${spacing.contentMaxWidth}px` } as React.CSSProperties}
      >
        <h1 className="text-center text-purple-800 font-bold mb-8 text-2xl sm:text-3xl lg:text-4xl">
          Account Dashboard
        </h1>

        {userData && (
          <AccountSummary
            accountBalance={userData.accountBalance || 0}
            totalEarnings={userData.totalEarnings || 0}
            totalPayouts={userData.totalPayouts || 0}
            className="rounded-[var(--card-radius)] p-6 mb-6 shadow-md"
          />
        )}

        <div className="space-y-6">
          <div className="flex flex-wrap justify-center gap-3">
            {[
              { value: 'overview', label: 'Overview' },
              { value: 'fund-accounts', label: 'Fund Accounts' },
              { value: 'transactions', label: 'Transactions' },
              { value: 'upcoming-events', label: 'Upcoming Events' }
            ].map((tab) => (
              <Button
                key={tab.value}
                type="button"
                variant={activeTab === tab.value ? "default" : "outline"}
                onClick={() => setActiveTab(tab.value)}
                className={`px-4 py-2 rounded transition-colors duration-200 
                  ${activeTab === tab.value ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600 hover:bg-purple-50"}`}
              >
                {tab.label}
              </Button>
            ))}
          </div>

          <div className="mt-6">
            {activeTab === 'overview' && (
              <div className="grid gap-6 md:grid-cols-2">
                <RecentTransactions 
                  transactions={transactions}
                  className="rounded-[var(--card-radius)] p-6 shadow-md"
                  showAll={false}
                />
                <UpcomingEvents 
                  events={upcomingEvents}
                  className="rounded-[var(--card-radius)] p-6 shadow-md"
                  showAll={false}
                />
              </div>
            )}
            {activeTab === 'fund-accounts' && (
              <FundAccountsPanel
                fundAccounts={userData?.fundAccounts || []}
                onDeleteClick={(id) => {
                  setAccountToDelete(id);
                  setIsDeleteDialogOpen(true);
                }}
                deletingId={deletingId}
                spacing={spacing}
                userData={userData}
                toast={toast}
              />
            )}
            {activeTab === 'transactions' && (
              <RecentTransactions 
                transactions={transactions}
                className="rounded-[var(--card-radius)] p-6 shadow-md"
                showAll={true}
              />
            )}
            {activeTab === 'upcoming-events' && (
              <UpcomingEvents 
                events={upcomingEvents}
                className="rounded-[var(--card-radius)] p-6 shadow-md"
                showAll={true}
              />
            )}
          </div>
        </div>

        <AlertDialog 
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => accountToDelete && handleDeleteAccount(accountToDelete)}
          title="Are you sure?"
          description="This action cannot be undone. This will permanently delete your fund account."
          confirmText="Delete"
          cancelText="Cancel"
        />

      </div>
    </div>
  );
};
